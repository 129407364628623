import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { device } from 'styles/device';

// Define new const with bold style
const headingStyle = css`
  font-weight: bold;
`;

// Define typography styles
export const H1 = styled.h1`
  font-size: 32px;
  font-weight: normal;

  @media ${device.laptop} {
    font-size: 50px;
  }
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: normal;

  @media ${device.laptop} {
    font-size: 35px;
  }
`;

export const H3 = styled(motion.h3)`
  font-size: 20px;
  font-weight: normal;

  @media ${device.laptop} {
    font-size: 26px;
  }
`;

export const Subtitle = styled(motion.h3)`
  font-size: 20px;
  ${headingStyle}
  
  @media ${device.laptop} {
    font-size: 23px;
  }
`;

export const Text = styled(motion.p)`
  color: ${({ theme, color }) => theme[color] || theme.black};
  /* font-family: 'paralucent-light', sans-serif; */
  font-family: ${(props) => (props.bold ? "'paralucent-medium', sans-serif" : " 'paralucent-light', sans-serif")};
  font-size: ${(props) => {
    switch (props.size) {
      case 'xlarge':
        return '24px';
      case 'large':
        return '20px';
      case 'medium':
        return '16px';
      case 'small':
        return '14px';
      case 'xsmall':
        return '12px';
      default:
        return '16px';
    }
  }};

  @media ${device.laptop} {
    font-size: ${(props) => {
      switch (props.size) {
        case 'xlarge':
          return '30px';
        case 'large':
          return '24px';
        case 'medium':
          return '18px';
        case 'small':
          return '16px';
        case 'xsmall':
          return '14px';
        default:
          return '18px';
      }
    }};
  }
`;

export const Quote = styled.q`
  display: block;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  @media ${device.laptop} {
    text-align: left;

    font-size: 52px;
  }
`;

// animated text
export const AnimatedText = styled.div`
  font-family: 'paralucent-light', sans-serif;
  font-size: 16px;
  font-weight: ${({ weight }) => weight};
  @media ${device.laptop} {
    font-size: 21px;
  }
`;

export const StyledUnderLine = styled(motion.hr)`
  background-color: ${({ primary, theme }) => (primary ? theme.orange : theme.green)};
  border: none;
  height: 0.125em;
  position: absolute;
  transform: rotate(-1deg);
  width: 100%;
`;

export const UnderlinedText = styled(motion.div)`
  display: inline-block;
  font-family: 'paralucent-medium', sans-serif;
  position: relative;
  width: fit-content;
`;

export const Small = styled.small`
  font-size: 80%;
`;
